export enum AuthActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/API_RESPONSE_ERROR",

  LOGIN_USER = "@@auth/LOGIN_USER",
  LOGIN_SSO_USER = "@@auth/LOGIN_SSO_USER",
  LOGOUT_USER = "@@auth/LOGOUT_USER",
  SIGNUP_USER = "@@auth/SIGNUP_USER",
  FORGOT_PASSWORD = "@@auth/FORGOT_PASSWORD",
  FORGOT_PASSWORD_CHANGE = "@@auth/FORGOT_PASSWORD_CHANGE",
  RESET = "@@auth/RESET",
  NOTIFY_USER = "@@auth/NOTIFY_USER",
  HIDE_NOTIFY = "@@auth/HIDE_NOTIFY",

  GET_ALL_USERS = "@@auth/GET_ALL_USERS",
  ADD_USER = "@@auth/ADD_USER",
  UPDATE_USER = "@@auth/UPDATE_USER",
  GET_USER_BY_ID = "@@auth/GET_USER_BY_ID",
  DELETE_USER_BY_ID = "@@auth/DELETE_USER_BY_ID",
  GET_ALL_ROLES = "@@auth/GET_ALL_ROLES",

  GET_ACTIVITY_LOG = "@@auth/GET_ACTIVITY_LOG",
}
