import { APICore } from "./apiCore";

const api = new APICore();

function login(params: { email: string; password: string }) {
  const baseUrl = "/auth/login/";
  return api.create(`${baseUrl}`, params);
}
function loginSSOApi() {
  const baseUrl = "/auth/sso";
  return api.get(`${baseUrl}`, {});
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}
function getAllUsersApi(payload: { params: Object }) {
  const baseUrl = "/user";
  return api.get(`${baseUrl}`, payload.params ? payload.params : "");
}
function addUsersApi(payload: { params: Object }) {
  const baseUrl = "/user";
  return api.create(`${baseUrl}`, payload.params ? payload.params : "");
}
function updateUsersApi(payload: { params: Object }) {
  const baseUrl = "/user";
  return api.update(`${baseUrl}`, payload.params ? payload.params : "");
}
function deleteUsersApi(payload: { params: Object }) {
  const baseUrl = "/user";
  return api.delete(`${baseUrl}`, payload.params ? payload.params : "");
}
function getAllRolesApi(payload: { params: Object }) {
  const baseUrl = "/auth/roles";
  return api.get(`${baseUrl}`, payload.params ? payload.params : "");
}

function forgotPasswordConfirm(params: { email: string }) {
  const baseUrl = "/password/reset/confirm/";
  return api.create(`${baseUrl}`, params);
}
function getActivityLogsAPi(params: Object) {
  const baseUrl = "/logs/activity";
  return api.get(`${baseUrl}`, params);
}
export {
  login,
  logout,
  signup,
  forgotPassword,
  forgotPasswordConfirm,
  getAllUsersApi,
  getAllRolesApi,
  addUsersApi,
  loginSSOApi,
  deleteUsersApi,
  updateUsersApi,
  getActivityLogsAPi,
};
