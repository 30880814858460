import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { APICore, setAuthorization } from "helpers/api/apiCore";
import {
  login as loginApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "helpers";
import { authApiResponseSuccess, authApiResponseError } from "./actions";
import { AuthActionTypes } from "./constants";
import {
  addUsersApi,
  deleteUsersApi,
  getActivityLogsAPi,
  getAllRolesApi,
  getAllUsersApi,
  loginSSOApi,
  updateUsersApi,
} from "helpers/api/auth";
import { Roles } from "auth/roles";

type UserData = {
  payload: {
    username: string;
    password: string;
    fullname: string;
    email: string;
  };
  type: string;
};

const api = new APICore();
const RolePriority = {
  [Roles.APP_ADMIN]: 1,
  [Roles.ADMIN]: 1,
  [Roles.USER]: 2,
  [Roles.GUEST]: 2,
};

// Function to determine the role ID
const getRoleId = (user: any) => {
  const userRoles = user.user.roles; // Assume `user.user.roles` is an array of roles
  const roleId = userRoles
    .map((role: any) => RolePriority[role]) // Map roles to their priority values
    .sort((a: any, b: any) => a - b)[0]; // Get the highest-priority role (smallest role ID)
  return roleId || RolePriority[Roles.GUEST]; // Default to `Roles.GUEST` if no role matches
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }: any): SagaIterator {
  try {
    const response = yield call(loginApi, { email: username, password });
    const user = response.data;
    const updatedUser = {
      ...user,
      roleid: getRoleId(user),
    };
    api.setLoggedInUser(updatedUser);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, updatedUser));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}
function* loginSSO(): SagaIterator {
  try {
    const response = yield call(loginSSOApi);
    const user = response.data;
    const updatedUser = {
      ...user,
      roleid: getRoleId(user),
    };
    api.setLoggedInUser(updatedUser);
    yield put(
      authApiResponseSuccess(AuthActionTypes.LOGIN_SSO_USER, updatedUser)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_SSO_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    //    yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: { fullname, email, password },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { fullname, email, password });
    const user = response.data;
    // api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { username });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}
function* getAllUsersSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getAllUsersApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(authApiResponseSuccess(AuthActionTypes.GET_ALL_USERS, data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.GET_ALL_USERS, error));
  }
}
function* addUserSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(addUsersApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(authApiResponseSuccess(AuthActionTypes.ADD_USER, data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.ADD_USER, error));
  }
}
function* updateUserSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateUsersApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(authApiResponseSuccess(AuthActionTypes.UPDATE_USER, data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.UPDATE_USER, error));
  }
}
function* getUserByIdSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getAllUsersApi, { params });
    const data = response.data[0];
    // NOTE - You can change this according to response format from your api
    yield put(authApiResponseSuccess(AuthActionTypes.GET_USER_BY_ID, data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.GET_USER_BY_ID, error));
  }
}
function* deleteUserByIdSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(deleteUsersApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(authApiResponseSuccess(AuthActionTypes.DELETE_USER_BY_ID, data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.DELETE_USER_BY_ID, error));
  }
}
function* getAllRolesSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getAllRolesApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(authApiResponseSuccess(AuthActionTypes.GET_ALL_ROLES, data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.GET_ALL_ROLES, error));
  }
}
function* getActivityLogsSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getActivityLogsAPi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(authApiResponseSuccess(AuthActionTypes.GET_ACTIVITY_LOG, data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.GET_ACTIVITY_LOG, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}
export function* watchLoginSSOUser() {
  yield takeEvery(AuthActionTypes.LOGIN_SSO_USER, loginSSO);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}
export function* watchgetAllUsersSaga() {
  yield takeEvery(AuthActionTypes.GET_ALL_USERS, getAllUsersSaga);
}
export function* watchaddUserSaga() {
  yield takeEvery(AuthActionTypes.ADD_USER, addUserSaga);
}
export function* watchupdateUserSaga() {
  yield takeEvery(AuthActionTypes.UPDATE_USER, updateUserSaga);
}
export function* watchgetUserByIdSaga() {
  yield takeEvery(AuthActionTypes.GET_USER_BY_ID, getUserByIdSaga);
}
export function* watchdeleteUserByIdSaga() {
  yield takeEvery(AuthActionTypes.DELETE_USER_BY_ID, deleteUserByIdSaga);
}
export function* watchgetgetAllRolesSaga() {
  yield takeEvery(AuthActionTypes.GET_ALL_ROLES, getAllRolesSaga);
}
export function* watchgetActivityLogsSaga() {
  yield takeEvery(AuthActionTypes.GET_ACTIVITY_LOG, getActivityLogsSaga);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginSSOUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchgetAllUsersSaga),
    fork(watchgetgetAllRolesSaga),
    fork(watchgetUserByIdSaga),
    fork(watchaddUserSaga),
    fork(watchupdateUserSaga),
    fork(watchdeleteUserByIdSaga),
    fork(watchgetActivityLogsSaga),
  ]);
}

export default authSaga;
