// roles.ts
export const Roles = {
  APP_ADMIN: "AppAdmin",
  ADMIN: "Admin",
  USER: "user",
  GUEST: "guest",
};

// permissions.ts
export const Permissions = {
  VIEW_DASHBOARD: "VIEW_DASHBOARD",
  EDIT_USER: "EDIT_USER",
  DELETE_POST: "DELETE_POST",
  EDIT_QUOTE: "EDIT_QUOTE",
  APPROVE_QUOTE: "APPROVE_QUOTE",
  // Add more as needed
};
