import { INotification } from "../modals/notification.modal";
import { AuthActionTypes } from "./constants";

export type AuthActionType = {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGIN_SSO_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.NOTIFY_USER
    | AuthActionTypes.HIDE_NOTIFY
    | AuthActionTypes.GET_ALL_USERS;

  payload: {} | any;
};

type User = {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
};

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: User | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (
  username: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { username, password },
});
export const loginSSOUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGIN_SSO_USER,
  payload: {},
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (
  fullname: string,
  email: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { fullname, email, password },
});

export const forgotPassword = (username: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { username },
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});

export const notifyUser = (params: INotification): AuthActionType => ({
  type: AuthActionTypes.NOTIFY_USER,
  payload: { params },
});
export const hideNotificationAction = (
  params: INotification
): AuthActionType => ({
  type: AuthActionTypes.HIDE_NOTIFY,
  payload: { params },
});
export const getAllUsersAction = (params?: any) => ({
  type: AuthActionTypes.GET_ALL_USERS,
  payload: { params },
});
export const addUserAction = (params?: any) => ({
  type: AuthActionTypes.ADD_USER,
  payload: { params },
});
export const updateUserAction = (params?: any) => ({
  type: AuthActionTypes.UPDATE_USER,
  payload: { params },
});
export const getUserByIdAction = (params?: any) => ({
  type: AuthActionTypes.GET_USER_BY_ID,
  payload: { params },
});
export const deleteUserByIdAction = (params?: any) => ({
  type: AuthActionTypes.DELETE_USER_BY_ID,
  payload: { params },
});
export const getAllRolesAction = (params?: any) => ({
  type: AuthActionTypes.GET_ALL_ROLES,
  payload: { params },
});

export const getActivityLogsAction = (params?: any) => ({
  type: AuthActionTypes.GET_ACTIVITY_LOG,
  payload: { params },
});
