import { APICore } from "helpers/api/apiCore";
import { INotification, ENotificationType } from "../modals/notification.modal";
import { AuthActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(),
  loading: false,
  notification: {
    desc: "",
    type: ENotificationType.SUCCESS,
    showNotify: false,
  },
};

type UserData = {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
};

type AuthActionType = {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGIN_SSO_USER
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.NOTIFY_USER
    | AuthActionTypes.HIDE_NOTIFY
    | AuthActionTypes.GET_ALL_USERS
    | AuthActionTypes.ADD_USER
    | AuthActionTypes.UPDATE_USER
    | AuthActionTypes.GET_USER_BY_ID
    | AuthActionTypes.DELETE_USER_BY_ID
    | AuthActionTypes.GET_ALL_ROLES
    | AuthActionTypes.GET_ACTIVITY_LOG;
  payload: {
    actionType?: string;
    data?: UserData | {};
    error?: string;
    params?: Object;
  };
};

type State = {
  user?: UserData | {};
  loading?: boolean;
  value?: boolean;
  notification: INotification;
};

const Auth = (state: State = INIT_STATE, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
          };
        }
        case AuthActionTypes.LOGIN_SSO_USER: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            userSignUp: true,
            loading: false,
          };
        }
        case AuthActionTypes.LOGOUT_USER: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          return {
            ...state,
            resetPasswordSuccess: action.payload.data,
            loading: false,
            passwordReset: true,
          };
        }
        case AuthActionTypes.GET_ALL_USERS: {
          return {
            ...state,
            users: action.payload.data,
          };
        }
        case AuthActionTypes.ADD_USER: {
          return {
            ...state,
            users: action.payload.data,
          };
        }
        case AuthActionTypes.UPDATE_USER: {
          return {
            ...state,
            users: action.payload.data,
          };
        }
        case AuthActionTypes.GET_USER_BY_ID: {
          return {
            ...state,
            edituser: action.payload.data,
          };
        }
        case AuthActionTypes.DELETE_USER_BY_ID: {
          return {
            ...state,
            users: action.payload.data,
          };
        }
        case AuthActionTypes.GET_ALL_ROLES: {
          return {
            ...state,
            roles: action.payload.data,
          };
        }
        case AuthActionTypes.GET_ACTIVITY_LOG: {
          return {
            ...state,
            logs: action.payload.data,
          };
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.LOGIN_SSO_USER: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            passwordReset: false,
          };
        }
        case AuthActionTypes.GET_ALL_USERS: {
          return {
            ...state,
            error: action.payload.error,
          };
        }
        case AuthActionTypes.ADD_USER: {
          return {
            ...state,
            error: action.payload.error,
          };
        }
        case AuthActionTypes.UPDATE_USER: {
          return {
            ...state,
            error: action.payload.error,
          };
        }
        case AuthActionTypes.GET_USER_BY_ID: {
          return {
            ...state,
            error: action.payload.error,
          };
        }
        case AuthActionTypes.DELETE_USER_BY_ID: {
          return {
            ...state,
            error: action.payload.error,
          };
        }
        case AuthActionTypes.GET_ALL_ROLES: {
          return {
            ...state,
            error: action.payload.error,
          };
        }
        case AuthActionTypes.GET_ACTIVITY_LOG: {
          return {
            ...state,
            error: action.payload.error,
          };
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.LOGIN_USER:
      return { ...state, loading: true, userLoggedIn: false };
    case AuthActionTypes.LOGIN_SSO_USER:
      return { ...state, loading: true, userLoggedIn: false };
    case AuthActionTypes.SIGNUP_USER:
      return { ...state, loading: true, userSignUp: false };
    case AuthActionTypes.LOGOUT_USER:
      return { ...state, loading: true, userLogout: false };
    case AuthActionTypes.NOTIFY_USER:
      return {
        ...state,
        notification: action.payload.params,
      };
    case AuthActionTypes.HIDE_NOTIFY:
      return {
        ...state,
        notification: action.payload.params,
      };
    case AuthActionTypes.GET_ALL_USERS:
      return {
        ...state,
        users: action.payload.params,
      };
    case AuthActionTypes.ADD_USER:
      return {
        ...state,
        users: action.payload.params,
      };
    case AuthActionTypes.UPDATE_USER:
      return {
        ...state,
        users: action.payload.params,
      };
    case AuthActionTypes.GET_USER_BY_ID:
      return {
        ...state,
        edituser: action.payload.params,
      };
    case AuthActionTypes.DELETE_USER_BY_ID:
      return {
        ...state,
        users: action.payload.params,
      };
    case AuthActionTypes.GET_ALL_ROLES:
      return {
        ...state,
        roles: action.payload.params,
      };
    case AuthActionTypes.GET_ACTIVITY_LOG:
      return {
        ...state,
        logs: action.payload.params,
      };
    case AuthActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
        resetPasswordSuccess: null,
      };
    default:
      return { ...state };
  }
};

export default Auth;
