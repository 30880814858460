import React, { createContext, useContext, PropsWithChildren } from 'react';

// Define the shape of the auth context
interface AuthContextType {
  role: string;
  permissions: string[];
}

// Create the context with a default undefined value
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define the props for the provider component, including children
type AuthProviderProps = PropsWithChildren<{
  role: string;
  permissions: string[];
}>;

const AuthProvider: React.FC<AuthProviderProps> = ({ children, role, permissions }) => {
  return (
    <AuthContext.Provider value={{ role, permissions }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context in components
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Export both AuthProvider and AuthContext
export { AuthProvider, AuthContext };
